meta.foundation-version {
  font-family: "/5.4.7/"; }

meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0em; }

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width:40.063em)/";
  width: 40.063em; }

meta.foundation-mq-large {
  font-family: "/only screen and (min-width:64.063em)/";
  width: 64.063em; }

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:90.063em)/";
  width: 90.063em; }

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:120.063em)/";
  width: 120.063em; }

meta.foundation-data-attribute-namespace {
  font-family: false; }

html, body {
  height: 100%; }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

html,
body {
  font-size: 100%; }

body {
  background: white;
  color: #222222;
  padding: 0;
  margin: 0;
  /*font-family: 'Museo Sans W01_300', Helvetica, Roboto, Arial, sans-serif;*/
  font-weight: normal;
  font-style: normal;
  line-height: 1.5;
  position: relative;
  cursor: auto; }

a:hover {
  cursor: pointer; }

img {
  max-width: 100%;
  height: auto; }

img {
  image-rendering: -moz-crisp-edges;
  -ms-interpolation-mode: nearest-neighbor;
  -ms-interpolation-mode: bicubic;
  image-rendering: optimizeQuality;
}

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object {
  max-width: none !important; }

.left {
  float: left; }

.right {
  float: right; }

.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }
.clearfix:after {
  clear: both; }

.hide {
  display: none !important;
  visibility: hidden; }

.invisible {
  visibility: hidden; }

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

img {
  display: inline-block;
  vertical-align: middle; }

textarea {
  height: auto;
  min-height: 50px; }

select {
  width: 100%; }

.slicknav_menu {
  display: none;
}

.row {
  width: 100%;
  /*margin-left: auto;
  margin-right: auto;*/
  margin-top: 0;
  margin-bottom: 0;
  /*max-width: 62.5em;*/ }
  .row:before, .row:after {
    content: " ";
    display: table; }
  .row:after {
    clear: both; }
  .row.collapse > .column,
  .row.collapse > .columns {
    padding-left: 0;
    padding-right: 0; }
  .row.collapse .row {
    margin-left: 0;
    margin-right: 0; }
  .row .row {
    width: auto;
    margin-left: -0.9375em;
    margin-right: -0.9375em;
    margin-top: 0;
    margin-bottom: 0;
    max-width: none; }
    .row .row:before, .row .row:after {
      content: " ";
      display: table; }
    .row .row:after {
      clear: both; }
    .row .row.collapse {
      width: auto;
      margin: 0;
      max-width: none; }
      .row .row.collapse:before, .row .row.collapse:after {
        content: " ";
        display: table; }
      .row .row.collapse:after {
        clear: both; }

.column,
.columns {
  padding-left: 0.9375em;
  padding-right: 0.9375em;
  width: 100%;
  float: left; }

[class*="column"] + [class*="column"]:last-child {
  /*float: right;*/ }

[class*="column"] + [class*="column"].end {
  float: left; }

@media only screen {
  .column,
  .columns {
    position: relative;
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    float: left; }
}
@media only screen and (min-width: 40.063em) {
  .column,
  .columns {
    position: relative;
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    float: left; 
  }

  .push-0 {
    position: relative;
    left: 0%;
    right: auto; }

  .pull-0 {
    position: relative;
    right: 0%;
    left: auto; }

  .push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .push-3 {
    position: relative;
    left: 25%;
    right: auto; }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto; }

  .push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .push-6 {
    position: relative;
    left: 50%;
    right: auto; }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto; }

  .push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .push-9 {
    position: relative;
    left: 75%;
    right: auto; }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto; }

  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; } }
@media only screen and (min-width: 64.063em) {
  .large-push-0 {
    position: relative;
    left: 0%;
    right: auto; }

  .large-pull-0 {
    position: relative;
    right: 0%;
    left: auto; }

  .large-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .large-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .large-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .large-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .large-push-3 {
    position: relative;
    left: 25%;
    right: auto; }

  .large-pull-3 {
    position: relative;
    right: 25%;
    left: auto; }

  .large-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .large-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .large-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .large-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .large-push-6 {
    position: relative;
    left: 50%;
    right: auto; }

  .large-pull-6 {
    position: relative;
    right: 50%;
    left: auto; }

  .large-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .large-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .large-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .large-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .large-push-9 {
    position: relative;
    left: 75%;
    right: auto; }

  .large-pull-9 {
    position: relative;
    right: 75%;
    left: auto; }

  .large-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .large-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .large-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .large-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; }

  .column,
  .columns {
    position: relative;
    padding-left: 0.9375em;
    padding-right: 0.9375em;
    float: left; }

  .large-1 {
    width: 8.33333%; }

  .large-2 {
    width: 16.66667%; }

  .large-3 {
    width: 25%; }

  .large-4 {
    width: 33.33333%; }

  .large-5 {
    width: 41.66667%; }

  .large-6 {
    width: 50%; }

  .large-7 {
    width: 58.33333%; }

  .large-8 {
    width: 66.66667%; }

  .large-9 {
    width: 75%; }

  .large-10 {
    width: 83.33333%; }

  .large-11 {
    width: 91.66667%; }

  .large-12 {
    width: 100%; }

  .push-0 {
    position: relative;
    left: 0%;
    right: auto; }

  .pull-0 {
    position: relative;
    right: 0%;
    left: auto; }

  .push-1 {
    position: relative;
    left: 8.33333%;
    right: auto; }

  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto; }

  .push-2 {
    position: relative;
    left: 16.66667%;
    right: auto; }

  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto; }

  .push-3 {
    position: relative;
    left: 25%;
    right: auto; }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto; }

  .push-4 {
    position: relative;
    left: 33.33333%;
    right: auto; }

  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto; }

  .push-5 {
    position: relative;
    left: 41.66667%;
    right: auto; }

  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto; }

  .push-6 {
    position: relative;
    left: 50%;
    right: auto; }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto; }

  .push-7 {
    position: relative;
    left: 58.33333%;
    right: auto; }

  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto; }

  .push-8 {
    position: relative;
    left: 66.66667%;
    right: auto; }

  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto; }

  .push-9 {
    position: relative;
    left: 75%;
    right: auto; }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto; }

  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto; }

  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto; }

  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto; }

  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto; } }
.inline-list {
  margin: 0 auto 0 auto;
  /*margin-left: -1.375rem;*/
  margin-right: 0;
  padding: 0;
  list-style: none;}
  .inline-list > li {
    list-style: none;
    float: left;
    padding-left: 0.687rem;
    padding-right: 0.687rem;
    display: block; }
    .inline-list > li a {
      margin-top: 0.3rem;
      margin-bottom: 0.3rem;
    }
    .inline-list > li > * {
      display: block; }
    .inline-list > li .nav-primary__drop {
      display: none; }
      .nav-primary__drop li {
        padding-right: 0px;
        white-space: nowrap;}

    @media only screen and (max-width: 40.063em) {
      .message, .award, .awarddesc, .gradient.scfull, .navhr.hrlg {
        display: none !important;
      }
      .smlcontainer {
        min-height: initial !important;
      }
      .external .centerpadd .extContent.bottom a {
        width: 42% !important;
        margin-left: 2.6666% !important;
        margin-right: 2.6666% !important;
        clear: none !important;
      }
      #jobScrollLeft, #jobScrollRight {
        background-size: 50% !important;
        background-position: center center !important;
        top: -10px !important;
      }
      #teamScrollLeft, #teamScrollRight {
        background-size: 50% !important;
        background-position: center center !important;
        top: 12px !important;
      }
      #teamScrollLeft {
        left: 10px !important;
      }

      #teamScrollRight {
        right: 10px !important;
      }

      .external .centerpadd .extContent.bottom a:nth-of-type(2n+3) {
        clear: both !important;
      }

      .centernav .columns {
        border-left: none !important;
        border-right: none !important;
      }

      .centernav .left, .centernav .right {
        width: 100% !important;
      }

      .centernav .right {
        margin-top: 20px !important;
        border-left: none !important;
      }

      div.mht {
        height: auto !important;
        margin-top: 10px;
      }
      .jobsbanner {
        z-index: 4 !important;
        position: relative !important;
      }
      .jobs {
        -webkit-flex: none;
        flex: none;
        display: block !important;
      }
      .jobitem {
        width: 100% !important;
      }
      .jobsbanner .desc {
        width: 100% !important;
      }
      .clearsubmit {
        left: 50%;
        margin-left: -30%;
        margin-bottom: 45px !important;
      }
      .jobsubmit {
        margin-bottom: 0px !important;
        bottom: -20px !important;
      }
      .jobitem {
        margin-bottom: 55px !important;
      }
      .ss {
        margin-bottom: 5px !important;
        height: auto !important;
      }
      .home .ontop .fit {
        margin-top: 47px;
      }
      .cubecontainer .results.list {
        padding-top: 19px !important;
      }
      .pagebanner {
        margin-top: 52px;
      }
      .bottomborder .profile .st {
        margin-top: 15px;
        margin-bottom: 15px;
        padding-left: 0px;
        padding-right: 0px;
      }
      .side-nav .greensubmit {
        left: 50%;
        position: relative;
        margin-left: -30%;
        margin-top: 5px;
        width: 60%;
      }
      .sidebarColumn {
        border-right: none !important;
      }
      .topslider {
        overflow: hidden;
      }
      .jobsbanner .left {
        margin-top: 20px;
        text-align: center;
      }
      .jobsbanner .right {
        margin-bottom: 20px;
      }
      div.jobsbanner .left, div.jobsbanner .right {
        float: none !important;
        margin-left: auto;
        margin-right: auto;
        width: 80%;
      }
      .jobcube.side {
        max-width: 100% !important;
      }
      .jobcube.side .jobcube.options {
        padding-bottom: 10px;
      }
      .jobcube.side .jobcube.options, .jobcube.side .jobcube.blue {
        min-width: 100% !important;
      }
}

  @media only screen and (max-width: 40.063em) {
    /* here */
    .mobilemessage {
      display: block !important;
    }
    .topslider > .backstretch {
      display: none !important;
    }
    .sidebarColumn > div {
        display: none;
    }
}


.dropdown.button, button.dropdown {
  position: relative;
  outline: none;
  padding-right: 3.5625rem; }
  .dropdown.button::after, button.dropdown::after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    display: block;
    border-style: solid;
    border-color: white transparent transparent transparent;
    top: 50%; }
  .dropdown.button::after, button.dropdown::after {
    border-width: 0.375rem;
    right: 1.40625rem;
    margin-top: -0.15625rem; }
  .dropdown.button::after, button.dropdown::after {
    border-color: white transparent transparent transparent; }
  .dropdown.button.tiny, button.dropdown.tiny {
    padding-right: 2.625rem; }
    .dropdown.button.tiny:after, button.dropdown.tiny:after {
      border-width: 0.375rem;
      right: 1.125rem;
      margin-top: -0.125rem; }
    .dropdown.button.tiny::after, button.dropdown.tiny::after {
      border-color: white transparent transparent transparent; }
  .dropdown.button.small, button.dropdown.small {
    padding-right: 3.0625rem; }
    .dropdown.button.small::after, button.dropdown.small::after {
      border-width: 0.4375rem;
      right: 1.3125rem;
      margin-top: -0.15625rem; }
    .dropdown.button.small::after, button.dropdown.small::after {
      border-color: white transparent transparent transparent; }
  .dropdown.button.large, button.dropdown.large {
    padding-right: 3.625rem; }
    .dropdown.button.large::after, button.dropdown.large::after {
      border-width: 0.3125rem;
      right: 1.71875rem;
      margin-top: -0.15625rem; }
    .dropdown.button.large::after, button.dropdown.large::after {
      border-color: white transparent transparent transparent; }
  .dropdown.button.secondary:after, button.dropdown.secondary:after {
    border-color: #333333 transparent transparent transparent; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-justify {
  text-align: justify !important; }


@media only screen and (min-width: 100.063em) and (max-width: 99999999em) {
  .message {
    font-size: 35px !important;
    line-height: 45px !important;
  }
}

/* Typography resets */
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0; }

/* Default Link Styles */
a {
  color: #333333;
  text-decoration: none;
  line-height: inherit; }
  a:hover, a:focus {
    color: #616060; }
  a img {
    border: none; }

/* Default paragraph styles */
p {
  font-family: inherit;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  text-rendering: optimizeLegibility; }
  p.lead {
    font-size: 1.21875rem;
    line-height: 1.6; }
  p aside {
    font-size: 0.875rem;
    line-height: 1.35;
    font-style: italic; }

/* Default header styles */
h1, h2, h3, h4, h5, h6 {
  /*font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;*/
  font-weight: normal;
  font-style: normal;
  color: #222222;
  text-rendering: optimizeLegibility;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
  line-height: 1.4; }
  h1 small, h2 small, h3 small, h4 small, h5 small, h6 small {
    font-size: 60%;
    color: #6f6f6f;
    line-height: 0; }

h1 {
  font-size: 2.125rem; }

h2 {
  font-size: 1.6875rem; }

h3 {
  font-size: 1.375rem; }

h4 {
  font-size: 1.125rem; }

h5 {
  font-size: 1.125rem; }

h6 {
  font-size: 1rem; }

.subheader {
  line-height: 1.4;
  color: #6f6f6f;
  font-weight: normal;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem; }

hr {
  border: solid #dddddd;
  border-width: 1px 0 0;
  clear: both;
  margin: 1.25rem 0 1.1875rem;
  height: 0; }

/* Helpful Typography Defaults */
em,
i {
  font-style: italic;
  line-height: inherit; }

strong,
b {
  font-weight: bold;
  line-height: inherit; }

small {
  font-size: 60%;
  line-height: inherit; }

code {
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #333333;
  background-color: #f8f8f8;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
  padding: 0.125rem 0.3125rem 0.0625rem; }

/* Lists */
ul,
ol,
dl {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 1.25rem;
  list-style-position: outside;
  font-family: inherit; }

ul {
  margin-left: 1.1rem; }
  ul.no-bullet {
    margin-left: 0; }
    ul.no-bullet li ul,
    ul.no-bullet li ol {
      margin-left: 1.25rem;
      margin-bottom: 0;
      list-style: none; }

/* Unordered Lists */
ul li ul,
ul li ol {
  margin-left: 1.25rem;
  margin-bottom: 0; }
ul.square li ul, ul.circle li ul, ul.disc li ul {
  list-style: inherit; }
ul.square {
  list-style-type: square;
  margin-left: 1.1rem; }
ul.circle {
  list-style-type: circle;
  margin-left: 1.1rem; }
ul.disc {
  list-style-type: disc;
  margin-left: 1.1rem; }
ul.no-bullet {
  list-style: none; }

/* Ordered Lists */
ol {
  margin-left: 1.4rem; }
  ol li ul,
  ol li ol {
    margin-left: 1.25rem;
    margin-bottom: 0; }

/* Definition Lists */
dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold; }
dl dd {
  margin-bottom: 0.75rem; }

/* Page Lists */
.fit ul,
.fit ol,
.fit dl {
  font-size: 15px;
  line-height: 1.5 !important;
  margin-bottom: 10px !important;
  margin-top: 15px !important;
}

.fit li {
  margin-top: 10px !important;
}

/* Abbreviations */
abbr,
acronym {
  text-transform: uppercase;
  font-size: 90%;
  color: #222222;
  cursor: help; }

abbr {
  text-transform: none; }
  abbr[title] {
    border-bottom: 1px dotted #dddddd; }

/* Blockquotes */
blockquote {
  margin: 0 0 1.25rem;
  padding: 0.5625rem 1.25rem 0 1.1875rem;
  border-left: 1px solid #dddddd; }
  blockquote cite {
    display: block;
    font-size: 0.8125rem;
    color: #555555; }
    blockquote cite:before {
      content: "\2014 \0020"; }
    blockquote cite a,
    blockquote cite a:visited {
      color: #555555; }

blockquote,
blockquote p {
  line-height: 1.6;
  color: #6f6f6f; }

/* Microformats */
.vcard {
  display: inline-block;
  margin: 0 0 1.25rem 0;
  border: 1px solid #dddddd;
  padding: 0.625rem 0.75rem; }
  .vcard li {
    margin: 0;
    display: block; }
  .vcard .fn {
    font-weight: bold;
    font-size: 0.9375rem; }

.vevent .summary {
  font-weight: bold; }
.vevent abbr {
  cursor: default;
  text-decoration: none;
  font-weight: bold;
  border: none;
  padding: 0 0.0625rem; }

@media only screen and (min-width: 40.063em) {
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.4; }

  h1 {
    font-size: 2.75rem; }

  h2 {
    font-size: 2.3125rem; }

  h3 {
    font-size: 1.6875rem; }

  h4 {
    font-size: 1.4375rem; }

  h5 {
    font-size: 1.125rem; }

  h6 {
    font-size: 1rem; } }
/*
 * Print styles.
 *
 * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
 * Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com)
*/
.print-only {
  display: none !important; }

@media print {
  * {
    background: transparent !important;
    color: black !important;
    /* Black prints faster: h5bp.com/s */
    box-shadow: none !important;
    text-shadow: none !important; }

  a,
  a:visited {
    text-decoration: underline; }

  a[href]:after {
    content: " (" attr(href) ")"; }

  abbr[title]:after {
    content: " (" attr(title) ")"; }

  .ir a:after,
  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: ""; }

  pre,
  blockquote {
    border: 1px solid #999999;
    page-break-inside: avoid; }

  thead {
    display: table-header-group;
    /* h5bp.com/t */ }

  tr,
  img {
    page-break-inside: avoid; }

  img {
    max-width: 100% !important; }

  @page {
    margin: 0.5cm; }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }

  h2,
  h3 {
    page-break-after: avoid; }

  .hide-on-print {
    display: none !important; }

  .print-only {
    display: block !important; }

  .hide-for-print {
    display: none !important; }

  .show-for-print {
    display: inherit !important; } }

.reveal-modal-bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: black;
  background: rgba(0, 0, 0, 0.45);
  z-index: 1004;
  display: none;
  left: 0; }

.reveal-modal, dialog {
  visibility: hidden;
  display: none;
  position: absolute;
  z-index: 1005;
  width: 100vw;
  top: 0;
  border-radius: 3px;
  left: 0;
  background-color: white;
  padding: 1.25rem;
  border: solid 1px #666666;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  padding: 1.875rem; }

.label {
  font-weight: normal;
  font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  text-align: center;
  text-decoration: none;
  line-height: 1;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  margin-bottom: inherit;
  padding: 0.25rem 0.5rem 0.25rem;
  font-size: 0.6875rem;
  background-color: #2ba6cb;
  color: white; }
  .label.radius {
    border-radius: 3px; }
  .label.round {
    border-radius: 1000px; }
  .label.alert {
    background-color: #c60f13;
    color: white; }
  .label.warning {
    background-color: #f08a24;
    color: white; }
  .label.success {
    background-color: #5da423;
    color: white; }
  .label.secondary {
    background-color: #e9e9e9;
    color: #333333; }
  .label.info {
    background-color: #a0d3e8;
    color: #333333; }

[class*="block-grid-"] {
  display: block;
  padding: 0;
  margin: 0 -0.625rem; }
  [class*="block-grid-"]:before, [class*="block-grid-"]:after {
    content: " ";
    display: table; }
  [class*="block-grid-"]:after {
    clear: both; }
  [class*="block-grid-"] > li {
    display: block;
    height: auto;
    float: left;
    padding: 0 0.625rem 1.25rem; }

@media only screen and (min-width: 40.063em) {
  .searchbar .fm ::-webkit-input-placeholder {
      color:    #FFFFFF;
      opacity:  0;
  }
  .searchbar .fm :-moz-placeholder {
     color:    #FFFFFF;
     opacity:  0;
  }
  .searchbar .fm ::-moz-placeholder {
     color:    #FFFFFF;
     opacity:  0;
  }
  .searchbar .fm :-ms-input-placeholder {
     color:    #FFFFFF;
  }
}
@media only screen and (min-width: 64.063em) {
  .vhr {
    border-left: 2px solid #ffffff;
  }
  .mapslider .map-2 {
    padding-left: 0px;
  }
  .exppos .large-6.columns {
    padding-left: 0px;
  }

  .exppos .th {
    margin-left: -0.58em;
  }
  .columns.quote {
    font-size: 25px;
    font-size: 1.6em;
    line-height: 30px;
    padding: 0px;
    margin-top: 92px;
  }
  .ind {
    padding-left: 0.48em;
  }
}
.keystroke,
kbd {
  background-color: #ededed;
  border-color: #dddddd;
  color: #222222;
  border-style: solid;
  border-width: 1px;
  margin: 0;
  font-family: "Consolas", "Menlo", "Courier", monospace;
  font-size: inherit;
  padding: 0.125rem 0.25rem 0;
  border-radius: 3px; }

@media only screen and (min-width: 40.063em) and (max-width: 64.063em){
  .large-2.pull-8 {
    width: 17%;
    right: 73%;
  }
  .large-8.push-2 {
    width: 73%;
    left: 17%;
  }
  .large-2.pull-10 {
    width: 25%;
    right: 75%;
  }
  .large-10.push-2 {
    width: 75%;
    left: 25%;
  }

  .mapslider .map-1 {
    width: 11.6666% !important;
  }

  .mapslider .map-2 {
    width: 33.33333% !important;
  }

  .mapslider .map-3 {
    width: 10% !important;
    display: block !important;
  }

  .mapslider .map-4 {
    width: 33.33333% !important;
  }

  .mapslider .map-5 {
    width: 11.6666% !important;
  }
  .mapslider .exppos .row div:first-of-type .st {
    margin-bottom: 5px;
  }

  .mapslider .backstretch img {
    margin-left: -20px !important;
  }
  .indprofile {
    margin-right: 10% !important;
  }
  .indprofile .profimg {
    width: 35%;
  }
  .indprofile .proftxt {
    width: 65%;
  }
  footer .columns.rg {
    width: 30%;
    padding-left: 12%;
  }
}
@media only screen and (min-width: 64.063em) and (max-width: 90em){

  .large-2.pull-10 {
    width: 20%;
    right: 80%;
  }
  .large-10.push-2 {
    width: 80%;
    left: 20%;
  }
}
@media only screen and (min-width: 90.063em) and (max-width: 120em){

}
.nopadding {
  padding: 0;
}
@media only screen and (max-width: 35em){
  .profile {
    width: 100%;
    padding-left: 6em !important;
    padding-right: 6em !important;
  }
  .meettheteam .pagecontainer {
    -webkit-flex-wrap: wrap !important;
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
    min-height: initial !important;
  }
  .smlcontainer {
    -webkit-flex: none;
    flex: none;
    display: block !important;
  }
  .bottomborder .profile {
    border-right: 0px !important;
  }
  .centerbox {
    width: 100% !important;
  }
  .applications.centerbox .icontxt.lst {
    display: none !important;
  }
  .centerbox.applications .title {
    width: 75% !important;
  }
  .centerbox.applications .options {
    width: 25% !important;
  }
  .jobcube {
    -webkit-flex: 1 1 50% !important;
  }

  .mapslider .map-1 {
    width: 0% !important;
  }

  .mapslider .map-2 {
    width: 100% !important;
  }

  .mapslider .map-3 {
    width: 0% !important;
    display: none !important;
  }

  .mapslider .map-4 {
    width: 100% !important;
  }

  .mapslider .map-5 {
    width: 0% !important;
  }
  .mapslider .exppos .row div:first-of-type .st {
    margin-bottom: 10px;
  }

  .mapslider .backstretch img {
    margin-left: 0px !important;
    display: none !important;
  }

  .mapslider .backstretch {
    background: url("../img/mapmob.png") no-repeat;
    background-position: top center;
    background-size: cover;
  }

  .mapslider .flexrow {
    display: block !important;
  }

  .mapslider .exppos {
    position: initial;
  }

  .mapslider .greensubmit, .mapslider .blacksubmit {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .external .centerpadd .extContent.bottom {
    display: inline-block;
    width: 100%;
  }

  .external .centerpadd .extContent.bottom a {
    float: left;
    width: 26%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 3.6666%;
    margin-right: 3.6666%;
  }

  .external .centerpadd .extContent.bottom a:nth-of-type(3n+4) {
    clear: both;
  }
  .socialfooter .sociallinks span {
    display: none;
  }
  footer .columns.rg {
    width: 100%;
    padding-left: 0;
    text-align: center;
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 27em){
  .profile {
    width: 100%;
    padding-left: 2em !important;
    padding-right: 2em !important;
  }
  .profile img {
    width: 100%;
  }
  .columns.quote {
    font-size: 7vw;
    line-height: 8vw;
  }
}
@media only screen and (max-width: 27em){
  .profilecube {
    -webkit-flex: 1 1 50% !important;
  }
}
@media only screen and (min-width: 27.063em) and (max-width: 35em){
  .profile {
    width: 100%;
    padding-left: 6em !important;
    padding-right: 6em !important;
  }
  .columns.quote {
    font-size: 5.45vw;
    line-height: 7vw;
  }
  .mobilemessage {
    font-size: 5.5vw !important;
  }
  .profilecube {
    -webkit-flex: 1 1 40% !important;
  }
}
@media only screen and (min-width: 35.063em) and (max-width: 46em){
  .smlcontainer {
    display: -ms-flexbox;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-line-pack: end;
    -webkit-align-content: flex-end;
    align-content: flex-end;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }
  .profile {
    min-width: 200px;
    -webkit-flex: 1 1 40% !important;
  }
  .nav li .dd {
    position: relative;
    top: -1px;
  }
  .sociallinks {
    width: 18.9% !important;
  }
  .columns.quote {
    font-size: 4.45vw;
    line-height: 6vw;
    text-align: center;
  }
  .mobilemessage {
    font-size: 5vw !important;
    line-height: 6vw !important;
    padding: 0.4em 0.75em 0.4em 0.75em 6vw !important;
  }
  .centerbox.applications .title {
    width: 50% !important;
  }
  .centerbox.applications .options {
    width: 50% !important;
  }
  .profilecube {
    -webkit-flex: 1 1 30% !important;
  }
  #slidecontrol {
    display: none !important;
  }

  .mapslider .map-1 {
    width: 0% !important;
  }

  .mapslider .map-2 {
    width: 100% !important;
  }

  .mapslider .map-3 {
    width: 0% !important;
    display: none !important;
  }

  .mapslider .map-4 {
    width: 100% !important;
  }

  .mapslider .map-5 {
    width: 0% !important;
  }
  .mapslider .exppos .row div:first-of-type .st {
    margin-bottom: 10px;
  }

  .mapslider .backstretch img {
    margin-left: 0px !important;
    display: none !important;
  }

  .mapslider .backstretch {
    background: url("../img/mapmob.png") no-repeat;
    background-position: top center;
    background-size: cover;
  }

  .mapslider .flexrow {
    display: block !important;
  }

  .mapslider .exppos {
    position: initial;
  }

  .mapslider .greensubmit, .mapslider .blacksubmit {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .external .centerpadd .extContent.bottom {
    display: inline-block;
    width: 100%;
  }

  .external .centerpadd .extContent.bottom a {
    float: left;
    width: 26%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 3.6666%;
    margin-right: 3.6666%;
  }

  .external .centerpadd .extContent.bottom a:nth-of-type(3n+4) {
    clear: both;
  }

  .topslider .header .navbar .nav {
    display: inline-block;
    width: 100%;
    vertical-align: top;
  }

  .topslider .header .navbar .nav > li, .topslider .header .navbar .nav > ul {
    float: left;
    width: 16%;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 2%;
    margin-right: 2%;
    top: 12px;
    white-space: nowrap;
  }

  .topslider .header .navbar .nav > ul {
    top: -3px;
    position: absolute;
    right: 0px;
  }

  .topslider .header .navbar .nav > li:nth-of-type(4n+5) {
    clear: both;
  }
  footer .columns.rg {
    width: 30%;
    padding-left: 12%;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 54em){
  .jobsbanner .jobs .jobitem:nth-of-type(1n+3) {
    display: none;
  }
  .jobsbanner .jobs .jobitem:nth-of-type(1n+2) { 
    border-right: 1px solid #92b6db;
  }
  .message {
    font-size: 3vw !important;
    width: 33% !important;
  }
  .jobitem.jt {
    font-size: 80%;
  }
  .jobsbanner .desc {
      width: 95% !important;
      margin-bottom: 10px !important;
  }
  .jobsbanner .left {
    font-size: 25px !important;
  }
  .jobsbanner .jobsubmit, .newssubmit {
    height: 26px !important;
    min-height: 26px !important;
    font-size: 11px !important;
    width: 120px !important;
  }
  .searchbar .fmleft {
    display: none;
  }
  .searchbar:not(.sticky) .fm .searchinner {
    /*width: 75% !important;*/
    padding-left: 12.5%;
    padding-right: 12.5%;
  }
  .smlcontainer {
    min-height: initial !important;
  }
  .gradient.sidebarColumn .tsh.tm {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 18px !important;
  }
}
@media only screen and (min-width: 54em) and (max-width: 70em){
  .jobsbanner .jobs .jobitem:nth-of-type(1n+4) {
    display: none;
  }
  .jobsbanner .jobs .jobitem:nth-of-type(1n+3) { 
    border-right: 1px solid #92b6db;
  }
  .jobitem.jt {
    font-size: 80%;
  }
  .jobsbanner .desc {
      width: 95% !important;
      margin-bottom: 10px !important;
  }
  .jobsbanner .left {
    font-size: 25px !important;
  }
  .jobsbanner .jobsubmit, .newssubmit {
    height: 26px !important;
    min-height: 26px !important;
    font-size: 11px !important;
    width: 120px !important;
  }
}
@media only screen and (min-width: 35.063em) and (max-width: 60em){
  .jobcube {
    -webkit-flex: 1 1 40% !important;
  }
  .profilecube {
    -webkit-flex: 1 1 30% !important;
  }
  .gradient.sidebarColumn .tsh.tm {
    padding-left: 0 !important;
    padding-right: 0 !important;
    font-size: 18px !important;
  }
}


@media only screen and (max-width: 60em){
  .js-nav-primary {
    display: none;
  }
  .slicknav_menu {
    display: block !important;
  }
  .sociallinks {
    display: none !important;
  }
  .fixedlogo {
    top: 0px !important;
  }
  /* here */
}

@media only screen and (min-width: 40em) and (max-width: 60em){
  .pagebanner {
    margin-top: 0px !important;
  }
  .slicknav_menu {
    background: transparent !important;
    border-bottom: 0px !important;
    padding: 0px !important;
    position: absolute;
    width: 100%;
    z-index: 10;
  }
  .slicknav_menu .slicknav_btn {
    z-index: 1 !important;
    margin-top: 6px !important;
  }
  .darklogo {
    display: none !important;
  }
  .slicknav_nav {
    position: absolute !important;
    width: 100% !important;
  }
  .slicknav_menu .slicknav_nav {
    border-bottom: 1px solid #e1e1e1;
    background: #FFFFFF;
    padding-top: 30px;
    padding-bottom: 10px;
  }
}




@media only screen and (min-width: 60em) and (max-width: 76em){
  .jobcube {
    -webkit-flex: 1 1 40% !important;
  }
}
@media only screen and (min-width: 70em) and (max-width: 80em){
  .jobsbanner .jobs .jobitem:nth-of-type(1n+5) {
    display: none;
  }
  .jobsbanner .jobs .jobitem:nth-of-type(1n+4) { 
    border-right: 1px solid #92b6db;
  }
}
@media only screen and (min-width: 75em){
  .profilecube {
    -webkit-flex: 1 1 20% !important;
  }
  .sidebarColumn > div:not(.navtxt) {
      width: 55% !important;
  }
}
@media only screen and (max-width: 51em) {
  .navbar ul li {
    padding-left: 0 !important;
  }
  .nav-primary {
    padding-right: 0 !important;
  }
  .centerbox.applications {
    width: 100% !important;
  }
  .searchbar.sticky .fm {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .searchbar.sticky .searchinner {
    width: 100% !important;
    padding-left: 12.5% !important;
    padding-right: 12.5% !important;
    transition:width 1.25s;
    -webkit-transition:width 1.25s;
    -moz-transition: width 1.25s;
  }
  .jobcube.side {
    width: 100%;
    display: block;
    max-width: 100%;
    min-width: 100%;
  }
  .jobcube.side .options > a {
    width: 49% !important;
  }
  .jobsubmitlarge {
    width: 150px !important;
  }
  .socialfooter .sociallinks {
    margin-left: 20% !important;
    margin-right: 20% !important;
    width: 60% !important;
  }
  .padmob {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 1410px) {
  .maxwidth {
    border-left: 2px solid #e1e1e1;
    border-right: 2px solid #e1e1e1;
  }

  .searchbar.sticky .fm {
    padding-left: 0% !important;
    padding-right: 0% !important;
    left: 50% !important;
    margin-left: -705px !important;
    max-width: 1410px !important;
    border-left: 2px solid #e1e1e1;
    border-right: 2px solid #e1e1e1;
  }

  .searchbar.sticky .fm .searchinner {
    padding-left: 17.5% !important;
    padding-right: 17.5% !important;
    transition:width 1.25s;
    -webkit-transition:width 1.25s;
    -moz-transition: width 1.25s;
  }
}
@media only screen and (min-width: 40.063em) and (max-width: 44em){
  .profilecube {
    -webkit-flex: 1 1 40% !important;
  }
}
@media print { 
  .sidebarColumn, .jobcube.side, header.header, footer.row, .opennews, .searchbar, .pagebanner, .results, .inlinelogos, .socialfooter, .readmoregrad, .jobreadmore  {
    display: none !important;
  }
  .ontop {
    width: 100% !important;
    left: 0 !important;
  }
  .ontop .fit {
    border-left: 0 !important;
    left: 0 !important;
  }
  .newsrow:not(.open) {
    display: none;
  }
  .jobcube.display.single {
    height: auto !important;
  }
}
@media only screen and (max-width: 40.063em) {
  .nav .sociallinks, .slicknav_menu .sociallinks {
    display: none !important;
  }
  .searchbar.sticky {
    top: 1px !important;
  }
  .searchbar.sticky .fm {
    padding-left: 0% !important;
    padding-right: 0% !important;
    top: 47px !important;
    position: absolute !important;
  }
  .searchbar.sticky .fm .searchinner {
    padding-left: 0% !important;
    padding-right: 0% !important;
  }
  .searchbar .fm ::-webkit-input-placeholder {
      color:    #989898 !important;
      opacity:  1;
  }
  .searchbar .fm :-moz-placeholder {
     color:    #989898 !important;
     opacity:  1;
  }
  .searchbar .fm ::-moz-placeholder {
     color:    #989898 !important;
     opacity:  1;
  }
  .searchbar .fm :-ms-input-placeholder {
     color:    #989898 !important;
  }
  .jobcube.options a {
    text-align: center !important;
  }

  .jobcube.options a .l {
    float: none !important;
    display: inline-block !important;
  }
  .jobcube.side .options .b.l {
    width: 110px !important;
    text-align: left !important;
    vertical-align: top !important;
  }
  #jobsubmitsingle {
      width: 100% !important;
  }
  #jobsubmitsingle .b.l.large {
    text-align: center !important;
    width: 200px !important;
  }
  .jobcube.options .title.lower {
    text-align: center;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
  .jobcube.options .details {
    text-align: center;
    margin-bottom: 18px !important;
  }
  .results.list .pager {
    top: 18px !important;
  }
  .jobsbanner .jobs {
    width: 100% !important;
    margin-left: 0px !important;
  }
  #slidecontrol {
    display: none !important;
  }
  .indprofile {
    margin-left: 10% !important;
    margin-right: 10% !important;
  }
  .indprofile .profimg {
    margin-bottom: 30px;
    padding-right: 0 !important;
  }
  .indprofile .proftxt {
    margin-right: auto;
    margin-left: auto;
    float: none;
  }
  .indprofile .profimg {
    width: 70% !important;
    min-width: 200px !important;
    max-width: 260px !important;
    float: none !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .indprofile .proftxt {
    float: none !important;
  }
  .profile.selected {
    margin-top: 0.8em;
    margin-bottom: 0.8em;
  }
  .meettheteam .profile {
    text-align: left !important;
  }
  .meettheteam .profile > div {
    max-width: initial !important;
  }
  .meettheteam .profile.selected {
    max-width: initial !important;
  }
}